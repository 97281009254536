<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import { type ImageDto } from "luminary-shared";
import fallbackImg from "../../assets/fallbackImage.webp";
import LImageProvider from "./LImageProvider.vue";

type Props = {
    image?: ImageDto;
    aspectRatio?: keyof typeof aspectRatiosCSS;
    size?: keyof typeof sizes;
    rounded?: boolean;
};
const props = withDefaults(defineProps<Props>(), {
    aspectRatio: "video",
    size: "post",
    rounded: true,
});

const aspectRatiosCSS = {
    video: "aspect-video",
    square: "aspect-square",
    vertical: "aspect-[9/16]",
    wide: "aspect-[18/9]",
    classic: "aspect-[4/3]",
};

const sizes = {
    small: "w-20 max-w-20 min-w-20 md:w-24 md:max-w-24 md:min-w-24",
    thumbnail: "w-36 max-w-36 min-w-36 md:w-52 md:max-w-52 md:min-w-52",
    post: "w-full max-w-full",
};

const rounding = {
    small: "rounded-md",
    thumbnail: "rounded-lg",
    post: "md:rounded-lg",
};

const parentRef = ref<HTMLElement | undefined>(undefined);
const parentWidth = ref<number>(0);

onMounted(() => {
    parentWidth.value = parentRef.value?.clientWidth || 0;
    watch(
        () => parentRef.value?.clientWidth,
        (newWidth) => {
            parentWidth.value = newWidth || 0;
        },
    );
});
</script>

<template>
    <div ref="parentRef" :class="sizes[size]">
        <div
            :style="{ 'background-image': 'url(' + fallbackImg + ')' }"
            :class="[
                aspectRatiosCSS[aspectRatio],
                rounded ? rounding[size] : '',
                'w-full overflow-clip bg-cover bg-center object-cover shadow',
            ]"
        >
            <LImageProvider
                :parent-width="parentWidth"
                :image="props.image"
                :aspect-ratio="props.aspectRatio"
                :rounded="props.rounded"
                :size="props.size"
            />
        </div>

        <slot></slot>
    </div>
</template>
